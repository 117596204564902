import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/sellers";

export async function getSeller(uid: string): Promise<Learnlink.Seller.default> {
  const sellersRes = await axios.get<Learnlink.Seller.default>(
    `${ENDPOINT}/${uid}`,
  );

  return sellersRes.data;
}

export async function getSellers(path: string): Promise<Learnlink.Seller.default[]> {
  const sellersRes = await axios.get<Learnlink.Seller.default[]>(
    `${ENDPOINT}/${path}`,
  );

  return sellersRes.data;
}

export async function getMatchesForTeam(day: string): Promise<Learnlink.Seller.default[]> {
  const matchesRes = await axios.get<Learnlink.Seller.default[]>(
    `${ENDPOINT}/matches/team/${day}`,
  );

  return matchesRes.data;
}

export async function setBlockedStatus(sellerUID: string, blocked: boolean, sendSms = false): Promise<Learnlink.Seller.default> {
  const sellerRes = await axios.put<Learnlink.Seller.default>(
    `${ENDPOINT}/${sellerUID}/${blocked ? "block" : "unblock"}?sendSms=${sendSms ? "true" : "false"}`,
  );

  return sellerRes.data;
}

export async function setApplicationApprovedStatus(sellerUID: string, applicationApproved: boolean): Promise<Learnlink.Seller.default> {
  const sellerRes = await axios.put<Learnlink.Seller.default>(
    `${ENDPOINT}/applicationApproval/${sellerUID}/${applicationApproved}`);

  return sellerRes.data;
}

export async function setProStatus(sellerUID: string, pro: boolean): Promise<Learnlink.Seller.default> {
  const sellerRes = await axios.put<Learnlink.Seller.default>(
    `${ENDPOINT}/pro/${sellerUID}/${pro}`);

  return sellerRes.data;
}

export async function setInterviewApprovedStatus(sellerUID: string, interviewApproved: boolean): Promise<Learnlink.Seller.default> {
  const sellerRes = await axios.put<Learnlink.Seller.default>(
    `${ENDPOINT}/interviewApproval/${sellerUID}/${interviewApproved}`);

  return sellerRes.data;
}

export async function setTrainingApprovedStatus(sellerUID: string, trainingApproved: boolean): Promise<Learnlink.Seller.default> {
  const sellerRes = await axios.put<Learnlink.Seller.default>(
    `${ENDPOINT}/trainingApproval/${sellerUID}/${trainingApproved}`);

  return sellerRes.data;
}
