



























































import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { setBlockedStatus } from "@/api/seller-service";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "SellerBlockedSwitch",
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true,
    }
  },
  setup(props) {
    const vm = reactive({
      blockedFromQueue: false,
      dialogVisible: false,
      loading: false
    });

    onMounted(() => {
      vm.blockedFromQueue = !!props.seller.profile.blockedFromQueue;
    });

    async function setBlockedFromQueue(val: boolean, sendSms: boolean) {
      vm.loading = true;
      try {
        vm.blockedFromQueue = val;
        await setBlockedStatus(props.seller.user.uid, val, sendSms);

        store.commit("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
        vm.dialogVisible = false;
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    }

    return {
      setBlockedFromQueue,
      vm,
    };
  }
});
